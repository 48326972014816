<template>
  <HostLayoutContainer>
    <HostLayoutItem>
      <TitleWithUnderline :title="title" id="solution-modernization"/>
    </HostLayoutItem>
  </HostLayoutContainer>
  <HostLayoutContainer class="banner" :style="{ height: '835px' }">
    <HostLayoutItem>
      <Flow :flowData="flowData"/>
    </HostLayoutItem>
  </HostLayoutContainer>
</template>
<script>
import TitleWithUnderline from '@/components/title/title-with-underline.vue'
import Flow from '@/components/solution/solution-modernization/flow.vue'
export default {
  components: {
    TitleWithUnderline,
    Flow
  },
  setup () {
    return {
      title: { marginBottom: '86px', marginTop: '95px', text: '全新现代化办公解决方案' },
      flowData: [
        {
          titleTop: '驱动元宇宙',
          title: '为混合办公新世界予力每一人',
          content: [
            {
              contentTitle: '通过 Microsoft Teams 连接，协作并共同集中注意力',
              contentText: 'Mesh for Microsoft Teams 提供新一代2D加3D会议体验。通过个性化定制，控制自定义虚拟形象，并使用 AI 模仿动作和手势。使所有参会者可以使用虚拟形象和沉浸式空间出息会议，并且无需任何特殊装备即可实现。'
            },
            {
              contentTitle: '予力创作者推动创新',
              contentText: 'Microsoft Loop 结合强大灵活的虚拟画布和可移动组件的全新 APP。这些组件可以在应用程序之间自由移动并保持同步。使团队能够共同思考、计划和创造。'
            },
          ]
        },
        {
          title: '打造“超级连接”状态的业务模式',
          content: [
            {
              contentTitle: '随时随地与任何人在工作流程中协作',
              contentText: 'Dynamics 365 和 Microsoft 365 间的高效连接协作功能。使信息、人员和对话的连接成都提升，减少在应用程序之间的切换。无论何时何地，Context IQ 确保业务人员可以通过 Dynamics 365 和 Microsoft 365 的工作流，访问文档和记录、组织中的同事和对话。'
            },
            {
              contentTitle: '为每个客户触点提供互联体验',
              contentText: 'Microsoft Customer Experience Platform 使营销人员能够拥有自己的客户关系、创造品牌狂热粉丝并以耳目一新的方式与客户互动。在提供令人愉悦的客户体验的同时，帮助营销人员有效地利用和保护客户数据。'
            },
          ]
        },
        {
          title: '端到端的安全，保护所有事物',
          content: [
            {
              contentTitle: '针对中小企业的威胁保护',
              contentText: 'Microsoft Defender for Business 是专门为拥有300名员工的中小企业提供企业级终端安全功能的全新软件，帮助企业抵御网络攻击，包括勒索软件和恶意软件。Microsoft Defender for Business 将作为一个独立的集成保护解决方案，包含在 Microsoft 365 商业高级版中呈现。'
            },
            {
              contentTitle: '针对中小企业的威胁保护备份',
              contentText: '统一微软的威胁保护解决方案组件—SIEM Microsoft Sentinel 和 Microsoft Defender。现在，用户可以更轻松地对安全事件进行响应和调查，获得完整的上下文，也可以通过任一工具对事件进行进一步处理。'
            },
          ]
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.banner {
  background-image: url("/assets/product/platform_bg@3x.png");
}
</style>
